<template>
    <div>
        <v-tabs v-model="tab" @change="resumeSubscription(tab)" class="pl-4">
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="(s, index) in sArr" :key="index" :href="'#' + s.name" class="primary--text text-capitalize">
                <span :style="`color:${IDS.OrderStatusColor[s.status ? s.status[0] : 'DEFAULT']}`
                    ">
                    {{ s.label }} ({{
                        $data[s.name + "Count"] - Object.keys(timerFn[s.name]).length
                    }})
                </span>
            </v-tab>
        </v-tabs>

        <v-card class="elevation-0 px-6 my-6">
            <v-row class="justify-space-between">
                <div class="d-flex align-center" :style="'max-height: 70px;'">
                    <date-range-picker :ranges="dateOptions" class="ml-1 text-body-1" :autoApply="true" opens="right"
                        ref="picker" v-model="date">
                        <div class="d-flex " slot="input" slot-scope="picker" style="height: 28px; align-items: center">
                            <v-icon class="material-icons mr-2" style="font-size: 20px">mdi-calendar-range-outline</v-icon>
                            <template v-if="moment(picker.startDate).format('YYYY-MM-DD') !==
                                moment(picker.endDate).format('YYYY-MM-DD')">
                                {{ dateFormatter(picker.startDate) }} -
                                {{ dateFormatter(picker.endDate) }}
                            </template>
                            <template v-else>{{ dateFormatter(picker.startDate) }}</template>
                        </div>
                    </date-range-picker>
                    <div class="d-flex justify-center" lang="en-IN">
                        <div class="count-widget-assign px-3 py-2 ml-2"
                            :class="selectedFilter === 'active' ? 'count-widget-assign-active' : ''">
                            <p class="text-body-2 mb-0">Total Product KG</p>
                            <h4 class="text-subtitle-1 font-weight-bold">{{ (+totalProductKGSum).toLocaleString('en-IN',
                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00' }}</h4>
                        </div>
                        <div class="count-widget-assign px-3 py-2 ml-2"
                            :class="selectedFilter === 'active' ? 'count-widget-assign-active' : ''">
                            <p class="text-body-2 mb-0">Total Loading Weight</p>
                            <h4 class="text-subtitle-1 font-weight-bold">{{ (+totalLoadingWeightSum).toLocaleString('en-IN',
                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00' }}</h4>
                        </div>

                        <div class="count-widget-assign px-3 py-2 ml-2"
                            :class="selectedFilter === 'active' ? 'count-widget-assign-active' : ''">
                            <p class="text-body-2 mb-0">Total Amount</p>
                            <h4 class="text-subtitle-1 font-weight-bold">{{ '₹ ' + (+totalAmountSum).toLocaleString('en-IN',
                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</h4>
                        </div>
                    </div>
                </div>
                <div class="d-flex" :style="'margin-top:16px'">
                    <div class="mr-2">
                        <v-autocomplete :items="Distributors" item-text="name" clearable outlined dense
                            :filter="distributorFilter" item-value="id" v-model="selectedDistributors"
                            label="Search Distributor">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ data.item.name }} ({{ checkVal(data.item.orgCode) }})
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            data.item.mobile
                                        }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div class="">
                        <v-text-field dense clearable label="Search Sales Order" outlined @input="onSearch" />
                    </div>
                    <div class="ml-2" v-if="permissions.permissionMeta.create">
                        <v-btn depressed @click="startCreateOrder" class="primary">ADD ORDER</v-btn>
                    </div>
                </div>
            </v-row>
        </v-card>

        <v-tabs-items v-model="tab" class="px-4">
            <v-tab-item v-for="(s, index) in sArr" :key="index" :value="s.name">
                <v-data-table :headers="headers[s.name]" :loading="$apollo.queries[s.name].loading"
                    :footerProps="footerProps" class="elevation-0 order-table" fixed-header height="calc(100vh - 270px)"
                    :items="$data[s.name].filter((o) => o.isVisible)" :server-items-length="$data[s.name + 'Count']"
                    @click:row="openOrderDetail" :sort-by.sync="$data[s.name + 'Options'].sortBy"
                    :sort-desc.sync="$data[s.name + 'Options'].sortDesc" :options.sync="$data[s.name + 'Options']"
                    @update:options="onUpdateOptions">
                    <template v-slot:no-data>No Orders Available</template>
                    <template v-slot:no-results>No Orders Available</template>
                    <template v-slot:item.statusId="{ item }">
                        <v-btn icon :style="`color:${IDS.OrderStatusColor[item.status]}`"
                            @click.stop="openStatusMenu($event, item)">
                            <v-icon>mdi-checkbox-blank</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.print="{ item }">
                        <v-btn icon title="Print Order" :loading="item.loading" @click.stop="onPrintOrder(item)" v-if="![IDS.orderStatus.Canceled, IDS.orderStatus.Rejected].includes(
                            item.status
                        )
                            ">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.orderDate="{ item }">{{
                        moment(item.created_at).format("DD MMM YYYY hh:mm a")
                    }}</template>
                    <template v-slot:item.company="{ item }">{{ item.Distributor.name }}<br /><span class="grey--text">{{
                        item.Distributor.orgCode
                    }}</span></template>
                    <template v-slot:item.owner="{ item }">{{
                        (item.Distributor.Users.length && item.Distributor.Users[0].name) ||
                        "-"
                    }}</template>
                    <template v-slot:item.finalAmount="{ item }">{{
                        currencyFormatter(item.finalAmount)
                    }}</template>
                    <template v-slot:item.tallyInvoiceNum="{ item }">{{
                        item.tallyInvoiceNum || "-"
                    }}</template>
                    <template v-slot:item.invoiceDate="{ item }">{{
                        dateFormatter(item.invoiceDate)
                    }}</template>
                    <template v-slot:item.delivered_at="{ item }">{{
                        (item.delivered_at &&
                            moment(item.delivered_at).format("DD MMM YYYY hh:mm a")) ||
                        "-"
                    }}</template>
                    <template v-slot:item.expectedDeliveryDate="{ item }">{{
                        dateFormatter(item.expectedDeliveryDate)
                    }}</template>
                    <template v-slot:item.totalLoadingWeight="{ item }">
                        {{ item.totalLoadingWeight || "-" }}
                    </template>

                    <template v-slot:item.totalProductKG="{ item }">
                        {{ item.totalProductKG || "-" }}
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>

        <change-order-status :statusMenu.sync="statusMenu" :positionX="menuPositionX" :positionY="menuPositionY"
            :orderId="orderId" :statusId="statusId" />
        <order-detail :orderId="orderId" :orderDetailDialog="orderDetailDialog" @cancel="orderDetailDialog = false"
            v-if="orderDetailDialog" />
        <CreateSalesOrder @saveOrder="saveOrderDialog = false" :saveOrderDialog="saveOrderDialog" @showToast="showToaster"
            @cancel="saveOrderDialog = false" v-if="saveOrderDialog" />
        <Snackbar :toaster="toaster" />
    </div>
</template>

<script>
import { IDS } from "../Constants/IDS";
import Constants, { dateOptions } from "../Constants/Constants";
import {
    generate_FETCH_SALES_ORDERS,
    generate_SUBSCRIBE_SALES_ORDERS_COUNT,
} from "../graphql/subscriptions/Orders";
import moment from "moment";
import { debounce } from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { GET_ORGANIZATIONS_BY_TYPE } from "../graphql/queries/Organizations";
import ChangeOrderStatus from "../components/Orders/ChangeOrderStatus";
import OrderDetail from "../components/Orders/OrderDetail";
import Snackbar from "../components/Common/Snackbar";
import CreateSalesOrder from "../components/Orders/CreateSalesOrder";
import Vue from "vue";
import directPrint from "../components/Common/Helpers/directPrint";
let sArr = [
    { name: "allOrders", status: null, label: "All Orders" },
    {
        name: "accountVerificationOrders",
        status: [IDS.orderStatus.AccountVerification],
        label: "A/C Verification",
    },
    {
        name: "pendingForDispatchOrders",
        status: [IDS.orderStatus.PendingForDispatch],
        label: "Pending For Dispatch",
    },
    {
        name: "dispatchedOrders",
        status: [IDS.orderStatus.Dispatched],
        label: "Dispatched",
    },
    {
        name: "deliveredOrders",
        status: [IDS.orderStatus.Delivered],
        label: "Delivered",
    },
    {
        name: "canceledOrders",
        status: [IDS.orderStatus.Canceled],
        label: "cancelled",
    },
    {
        name: "rejectedOrders",
        status: [IDS.orderStatus.Rejected],
        label: "Rejected",
    },
];
let qObj = {},
    sObj = {},
    options = {
        page: 1,
        itemsPerPage: 25,
        offset: 0,
        limit: 25,
        sortBy: ["created_at"],
        sortDesc: [true],
    };
function commonVariables(s) {
    return {
        status: s.status ? { _in: s.status } : {},
        search: this.search,
        distributorIds: this.selectedDistributors
            ? { _in: [this.selectedDistributors] }
            : {},
        startDate: moment(this.date.startDate, "YYYY-MM-DD").startOf("day"),
        endDate: moment(this.date.endDate, "YYYY-MM-DD").endOf("day"),
    };
}
function pageVariables(s) {
    return {
        orderBy: this.prepareSortObject(
            this[s.name + "Options"].sortBy,
            this[s.name + "Options"].sortDesc
        ),
        offset: this[s.name + "Options"].offset,
        limit: this[s.name + "Options"].limit,
    };
}

sArr.forEach((s) => {
    qObj[s.name] = {
        /* Smart Query */
        query() {
            return generate_FETCH_SALES_ORDERS(s.name);
        },
        skip() {
            return s.name !== "allOrders";
        },
        fetchPolicy: "network-only",
        variables() {
            return {
                ...commonVariables.call(this, s),
                ...pageVariables.call(this, s),
            };
        },
        subscribeToMore: {
            document() {
                return generate_FETCH_SALES_ORDERS(s.name, "subscription");
            },
            variables() {
                return {
                    ...commonVariables.call(this, s),
                    ...pageVariables.call(this, s),
                };
            },
            updateQuery: function (
                previousQueryResult,
                { subscriptionData: { data } }
            ) {
                this.checkOrders(data, s.name);
            },
        },
        result({ data }) {
            this.checkOrders(data, s.name);
        },
    };
    sObj[s.name + "Count"] = {
        query() {
            return generate_SUBSCRIBE_SALES_ORDERS_COUNT(s.name + "Count");
        },
        variables() {
            return { ...commonVariables.call(this, s) };
        },
        result({ data }) {
            this[s.name + "Count"] = data[s.name + "Count"].aggregate.count;
            this[s.name + "Data"] = data[s.name + "Count"];
        },
    };
});
export default {
    name: "SalesOrder",
    components: {
        CreateSalesOrder,
        Snackbar,
        OrderDetail,
        ChangeOrderStatus,
        DateRangePicker,
    },
    apollo: {
        $subscribe: { ...sObj },
        ...qObj,
        Distributors: {
            query: GET_ORGANIZATIONS_BY_TYPE,
            fetchPolicy: "network-only",
            variables() {
                return { orgType: [IDS.organizationTypes.Distributor] };
            },
        },
    },
    data() {
        return {
            IDS,
            dateOptions,
            moment,
            tab: "allOrders",
            sArr: sArr,
            search: "%%",
            selectedFilter: '',
            ...sArr.reduce(
                (ac, s) => {
                    ac[s.name] = [];
                    ac[s.name + "Count"] = 0;
                    ac[s.name + "Data"] = {};
                    ac[s.name + "Options"] = { ...options };
                    ac.timerFn[s.name] = {};
                    return ac;
                },
                { timerFn: {} }
            ), // newOrders: []
            columns: [
                { text: "Status", value: "statusId", width: 10, sortable: false },
                { text: "", value: "print", width: 10, sortable: false },
                { text: "Order ID", value: "orderNum" },
                { text: "Order Date", value: "orderDate", width: 174 },
                { text: "Company Name", value: "company", width: 130 },
                {
                    text: "Distributor Name",
                    value: "owner",
                    width: 130,
                    sortable: false,
                },
                { text: "Mobile", value: "Distributor.mobile" },
                { text: "Amount", value: "finalAmount" },
                { text: "Order Item", value: "orderItemCount" },
                { text: "Total Loading Weight", value: "totalLoadingWeight" },
                { text: "Total Product KG", value: "totalProductKG" },
            ],
            footerProps: { "items-per-page-options": [25, 100, 150, 200, -1] },
            date: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            Distributors: [],
            selectedDistributors: null,
            saveOrderDialog: false,
            permissions: {},
            statusMenu: false,
            menuPositionX: 0,
            menuPositionY: 0,
            headers: {},
            orderDetailDialog: false,
            orderId: null,
            statusId: null,
            toaster: {},
        };
    },
    computed: {
        totalProductKGSum() {
            if (this.tab === 'allOrders') {
                return this.allOrdersData?.aggregate?.sum?.totalProductKG;
            } else if (this.tab === 'accountVerificationOrders') {
                return this.accountVerificationOrdersData?.aggregate?.sum?.totalProductKG;
            } else if (this.tab === 'pendingForDispatchOrders') {
                return this.pendingForDispatchOrdersData?.aggregate?.sum?.totalProductKG;
            } else if (this.tab === 'dispatchedOrders') {
                return this.dispatchedOrdersData?.aggregate?.sum?.totalProductKG;
            } else if (this.tab === 'deliveredOrders') {
                return this.deliveredOrdersData?.aggregate?.sum?.totalProductKG;
            } else if (this.tab === 'canceledOrders') {
                return this.canceledOrdersData?.aggregate?.sum?.totalProductKG;
            } else if (this.tab === 'rejectedOrders') {
                return this.rejectedOrdersData?.aggregate?.sum?.totalProductKG;
            }
            return '0';
        },

        totalLoadingWeightSum() {
            if (this.tab === 'allOrders') {
                return this.allOrdersData?.aggregate?.sum?.totalLoadingWeight;
            } else if (this.tab === 'accountVerificationOrders') {
                return this.accountVerificationOrdersData?.aggregate?.sum?.totalLoadingWeight;
            } else if (this.tab === 'pendingForDispatchOrders') {
                return this.pendingForDispatchOrdersData?.aggregate?.sum?.totalLoadingWeight;
            } else if (this.tab === 'dispatchedOrders') {
                return this.dispatchedOrdersData?.aggregate?.sum?.totalLoadingWeight;
            } else if (this.tab === 'deliveredOrders') {
                return this.deliveredOrdersData?.aggregate?.sum?.totalLoadingWeight;
            } else if (this.tab === 'canceledOrders') {
                return this.canceledOrdersData?.aggregate?.sum?.totalLoadingWeight;
            } else if (this.tab === 'rejectedOrders') {
                return this.rejectedOrdersData?.aggregate?.sum?.totalLoadingWeight;
            }
            return '0';
        },

        totalAmountSum() {
            if (this.tab === 'allOrders') {
                return this.allOrdersData?.aggregate?.sum?.finalAmount;
            } else if (this.tab === 'accountVerificationOrders') {
                return this.accountVerificationOrdersData?.aggregate?.sum?.finalAmount;
            } else if (this.tab === 'pendingForDispatchOrders') {
                return this.pendingForDispatchOrdersData?.aggregate?.sum?.finalAmount;
            } else if (this.tab === 'dispatchedOrders') {
                return this.dispatchedOrdersData?.aggregate?.sum?.finalAmount;
            } else if (this.tab === 'deliveredOrders') {
                return this.deliveredOrdersData?.aggregate?.sum?.finalAmount;
            } else if (this.tab === 'canceledOrders') {
                return this.canceledOrdersData?.aggregate?.sum?.finalAmount;
            } else if (this.tab === 'rejectedOrders') {
                return this.rejectedOrdersData?.aggregate?.sum?.finalAmount;
            }
            return '0';
        }
    },

    methods: {
        filterOrders(s, data) {
            if (["allOrders", "accountVerificationOrders"].includes(s.name))
                this[s.name] = data.filter(
                    (o) =>
                        o.status !== IDS.orderStatus.AccountVerification ||
                        (o.status === IDS.orderStatus.AccountVerification &&
                            moment().format("YYYY-MM-DD HH:mm:ss") >=
                            moment(o.created_at)
                                .add(5, "minutes")
                                .format("YYYY-MM-DD HH:mm:ss"))
                );
            else this[s.name] = data;
            /* Also need to update totalCount */
        },
        roundToTwoDecimalPlaces(number) {
            if (!number) return '0.00';
            const roundedNumber = Math.round(number * 100) / 100;
            return roundedNumber.toFixed(2);
        },
        prepareSortObject([sortBy], [order]) {
            order = order ? "desc" : "asc";
            if (
                [
                    "created_at",
                    "finalAmount",
                    "orderNum",
                    "tallyInvoiceNum",
                    "invoiceDate",
                    "orderItemCount",
                    "status",
                    "delivered_at",
                    "totalProductKG",
                    "totalLoadingWeight",
                ].includes(sortBy)
            )
                return { [sortBy]: order };
            if (sortBy === "company") return { Distributor: { name: order } };
            if (sortBy === "Distributor.mobile")
                return { Distributor: { mobile: order } };
            /*if (sortBy === 'customerType') return {Customer: {CustomerType: {type: order}}}; */
            return null;
        },
        async resumeSubscription(tab) {
            setTimeout(() => (this.$apollo.queries[tab].skip = false), 500);
        },
        onUpdateOptions(val) {
            if (val.itemsPerPage === -1) {
                //'ALL' Rows per page
                this[this.tab + "Options"].limit = this[this.tab + "Count"]; //assign total count
                this[this.tab + "Options"].offset = 0;
            } else {
                this[this.tab + "Options"].limit = val.itemsPerPage;
                this[this.tab + "Options"].offset = (val.page - 1) * val.itemsPerPage;
            }
        },
        distributorFilter(item, queryText) {
            queryText = queryText.toLowerCase();
            return (
                item.name?.toLowerCase().indexOf(queryText) > -1 ||
                item.mobile.toLowerCase().indexOf(queryText) > -1 ||
                item.orgCode?.toLowerCase().indexOf(queryText) > -1
            );
        },
        startCreateOrder() {
            this.saveOrderDialog = true;
        },
        openStatusMenu(e, row) {
            /* Disable for Delivered/Canceled/Rejected stage */
            if (
                [
                    IDS.orderStatus.Delivered,
                    IDS.orderStatus.Canceled,
                    IDS.orderStatus.Rejected,
                ].includes(row.status)
            )
                return;
            this.orderId = row.id;
            this.statusId = row.status;
            this.menuPositionX =
                e.clientX - e.offsetX - (e.target.clientWidth === 24 ? 6 : 0);
            this.menuPositionY =
                e.clientY +
                e.target.clientHeight -
                e.offsetY +
                (e.target.clientHeight === 24 ? 6 : 0);
            this.statusMenu = true;
        },
        openOrderDetail(row) {
            this.orderId = row.id;
            this.orderDetailDialog = true;
        },
        async onPrintOrder(row) {
            Vue.set(row, "loading", true);
            /* if(res.flag) console.log(res.data);
                  else this.toaster = { enable: true, color: 'red', message: (res.error&&res.error.error)||'Print API call failed' }; */
            let d = new directPrint(
                `${Constants.api_url}distributorOrderPdf/${row.id}`
            );
            await d.print();
            Vue.set(row, "loading", false);
        },
        onSearch: debounce(async function (val) {
            this.search = val ? "%" + val + "%" : "%%";
            sArr.forEach((s) => (this[s.name + "Options"] = { ...options })); //on search, reset page options
        }, 500),
        getHeaders() {
            sArr.forEach((s) => {
                if (s.name === "deliveredOrders")
                    this.headers[s.name] = [
                        ...this.columns,
                        { text: "Delivered Date", value: "delivered_at", width: 170 },
                        { text: "Expected Delivery Date", value: "expectedDeliveryDate" },
                        { text: "Invoice Number", value: "tallyInvoiceNum" },
                        { text: "Invoice Date", value: "invoiceDate" },
                    ];
                else if (["allOrders"].includes(s.name))
                    this.headers[s.name] = [
                        ...this.columns,
                        { text: "Invoice Number", value: "tallyInvoiceNum" },
                        { text: "Invoice Date", value: "invoiceDate" },
                    ];
                else if (["dispatchedOrders"].includes(s.name))
                    this.headers[s.name] = [
                        ...this.columns,
                        { text: "Invoice Number", value: "tallyInvoiceNum" },
                        { text: "Invoice Date", value: "invoiceDate" },
                        { text: "Expected Delivery Date", value: "expectedDeliveryDate" },
                    ];
                else this.headers[s.name] = this.columns;
            });
        },
        showToaster(data) {
            this.toaster = data;
        },
        checkOrders(data, name) {
            this.clearTimerByTab(name);
            data[name].forEach((o) => {
                let diff = moment().unix() - moment(o.created_at).unix(); //in seconds
                if (o.status === IDS.orderStatus.AccountVerification && diff < 300) {
                    //5 min
                    this.timerFn[name][o.id] = setTimeout(() => {
                        Vue.set(o, "isVisible", true);
                        delete this.timerFn[name][o.id];
                    }, (300 - diff) * 1000);
                } else o.isVisible = true;
            });
            this[name] = data[name];
        },
        clearTimerByTab(name) {
            Object.keys(this.timerFn[name]).forEach((k) => {
                clearTimeout(this.timerFn[name][k]);
            });
            this.timerFn[name] = {};
        },
    },
    beforeMount() {
        this.permissions = this.$store.getters.permissionByScreen("Distributors");
        this.getHeaders();
    },
    beforeDestroy() {
        Object.keys(this.timerFn).forEach((k) => {
            this.clearTimerByTab(k);
        });
    },
};
</script>

<style scoped>/deep/ tr:hover {
    cursor: pointer !important;
}
</style>
