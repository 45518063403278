<template>
  <div id="CreateSalesOrder">
    <v-dialog fullscreen scrollable persistent v-model="saveOrderDialog">
      <v-card class="pa-5">
        <v-card-title>
          <span class="text-h6">Create Sales Order</span>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row class="pt-0">
            <v-col class="px-8" style="border-right: 1px solid #E0E0E0;" cols="3">
              <h1 class="text-h4 mb-15 black--text font-weight-bold mt-5">Order</h1>
              <v-form ref="orderForm">
                <v-autocomplete class="rounded-0" :rules="[rules.distributorRequired]" :items="distributors" clearable outlined
                            :item-text="prepareDistributorName" item-value="id" :filter="distributorFilter"
                            v-model="order.to" label="Select Distributor*" @change="onSelectDistributor" return-object>
                 <!-- <template v-slot:selection="data">
                    {{ data.item.name }} - {{ data.item.orgCode }}
                  </template> -->
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2 font-weight-medium"> {{ data.item.name }}
                          ({{ checkVal(data.item.orgCode) }})
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-caption">{{ data.item.mobile }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-text-field  outlined class="rounded-0" label="Tally Invoice Number"
                              v-model="order.tallyInvoiceNum" :rules="[v => !!v || 'Tally Invoice number required']"/>

                <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                        transition="scale-transition" v-model="invoiceDateMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="rounded-0" clearable label="Invoice Date" outlined
                                  prepend-inner-icon="mdi-calendar-blank-outline"
                                  readonly v-bind="attrs" v-model="invoiceDateFormat" v-on="on"
                                  :rules="[v => !!v || 'Invoice Date required']"/>
                  </template>
                  <v-date-picker @input="invoiceDateMenu = false" v-model="order.invoiceDate" :max="moment().format('YYYY-MM-DD')"/>
                </v-menu>

                <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                        transition="scale-transition" v-model="expectedDateMenu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="rounded-0" clearable label="Expected Delivery Date" outlined
                                  prepend-inner-icon="mdi-calendar-blank-outline"
                                  readonly v-bind="attrs" v-model="expectedDateFormat" v-on="on"
                                  :rules="[v => !!v || 'Expected Delivery Date required']"/>
                  </template>
                  <v-date-picker @input="expectedDateMenu = false" v-model="order.expectedDeliveryDate" :min="moment().format('YYYY-MM-DD')"/>
                </v-menu>

              </v-form>
            </v-col>
            <v-col class="mt-5 px-8" cols="6" style="height: calc(100vh - 80px); overflow-y: auto">
              <div class="mb-10 ">
                <h1 class="text-h4 black--text font-weight-bold">Products
                <v-text-field outlined dense placeholder="Search Product ..." @input="onSearch" clearable class="float-right"/>
                </h1>
                <h6 class="text-caption font-weight-bold">{{ products.length }} ITEMS</h6>
              </div>
              <template v-for="(p, index) in products">
              <v-card class="py-0" :key="index" elevation="0" v-if="!p.isHidden" :disabled="!p.isEnable">
                <v-card-text class="py-0 px-0">
                  <v-row>
                    <v-col cols="2" class="text-center px-0 img-div" @click="openGallery([{image: p.image}])">
                      <v-img class="d-block mx-auto"
                             :src="p.image?imgWpx(p.image, 200):'../src/assets/logo.png'"
                             contain height="100"></v-img>
                      <v-chip label text-color="white" x-small v-if="p.packageType" class="mt-3"
                              :style="`background-color: ${packageColor[p.packageType]}`">
                        {{ p.packageType.toUpperCase() }}
                      </v-chip>
                    </v-col>
                    <v-col cols="10">
                      <!--                      :style="{color:price.Product.colorCode}"-->
                      <h6 class="text-h6 font-weight-medium black--text">{{ p.displayName }}</h6>
                      <p class="text-caption font-weight-medium mb-0">{{ p.noOfPieceInBox }} Pieces in {{p.containerType}}
                        ({{ currencyFormatter(p.priceObj.price) }})</p>
                      <div class="mt-5 d-flex align-center" v-if="p.isEnable">
                        <div>
                          <v-text-field style="width: 100px;" class="text-center stock-field rounded-0" append-icon="mdi-plus" dense @keydown="allowOnlyNumbers($event)"
                                        @click:prepend-inner="decreaseQuantity(index)" @click:append="increaseQuantity(index)"
                                        v-model="p.quantity" flat hide-details outlined prepend-inner-icon="mdi-minus"/>
                        </div>
                        <h6 class="text-h6 font-weight-regular ml-5">
                          {{ currencyFormatter(p.quantity * p.priceObj.price) }}</h6>
                      </div>
                      <div v-else class="red--text font-weight-bold mt-5">No Stock Available</div>
                    </v-col>
                    <v-col cols="12" class="pt-6 pb-9">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              </template>
            </v-col>
            <v-col class="px-8" style="border-left: 1px solid #E0E0E0;" cols="3">
              <div class="mb-10 mt-5">
                <h1 class="text-h4 black--text font-weight-bold">Pricing</h1>
                <h6 class="text-caption font-weight-bold">{{ calculateQuantity() }} BOXES ({{totalPieces}} PIECES)</h6>
              </div>

              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">Order Amount</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(orderPricing.totalAmount) }}</span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">CGST(6%)</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(orderPricing.cgst) }} </span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">SGST(6%)</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(orderPricing.sgst) }} </span>
              </div>
              <div class="d-flex justify-space-between ">
                <span class="text-body-1">Total GST(+12%)</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(orderPricing.gst) }} </span>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="d-flex justify-space-between green--text mb-14">
                <span class="text-body-1  font-weight-medium">Payable Amount</span>
                <span class="text-body-1  font-weight-medium">{{ currencyFormatter(orderPricing.finalAmount) }} </span>
              </div>
              <v-btn block elevation="0" class="py-7 text-capitalize text-body-1 rounded-0" :loading="loading"
                     color="primary" @click="placeOrder">Place Order
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <gallery id="gallery-create-order" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
  </div>
</template>

<script>
  import {GET_ALL_PRODUCTS_WITH_PRICES} from '../../graphql/queries/Products'
import { IDS } from '../../Constants/IDS'
import moment from 'moment'
import {GET_ORGANIZATIONS_WITH_OWNER_BY_TYPE} from '../../graphql/queries/Organizations'
import { CREATE_UPDATE_PURCHASE_ORDER_ITEMS } from '../../graphql/mutations/OrderStock'
import { CREATE_UPDATE_ORGANIZATION_STOCK, GET_ORGANIZATION_STOCK } from '../../graphql/queries/OrganizationStock'
import {allowOnlyNumbers} from "../../util/helpers";
import Vue from 'vue'
import gallery from '../../mixins/gallery';

export default {
  name: 'CreateSalesOrder',
  props: {
    saveOrderDialog: {type: [Boolean], default: false},
  },
  mixins: [gallery],
  data() {
    return {
      moment,
      productsUpdatedData: [],
      distributors: [], products: [], prices: [], invoiceDateMenu: false, expectedDateMenu: false, loading: false,
      order: { from: null, to: null, toUserId: null, tallyInvoiceNum: null, invoiceDate: null, expectedDeliveryDate: null },
      rules: { distributorRequired: value => !!value || 'Distributor Required.' },
      orderPricing: { totalAmount: 0, cgst: 0, sgst: 0, gst: 0, finalAmount: 0 },
      loggedInUser: JSON.parse(localStorage.user), allowOnlyNumbers,
      packageColor: JSON.parse(localStorage.packageColor),
    }
  },
  computed: {
    invoiceDateFormat: {
      get() {
        return this.order.invoiceDate ? moment(this.order.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
      },
      set() {
        this.order.invoiceDate = null
      },
    },
    expectedDateFormat: {
      get() {
        return this.order.expectedDeliveryDate ? moment(this.order.expectedDeliveryDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
      },
      set() {
        this.order.expectedDeliveryDate = null
      },
    },
    totalPieces: function(){
      return this.products.reduce((ac, p) => ac + (Number(p.quantity)*p.bunchKg), 0)
    }
  },
  apollo: {
    Distributors: {
      query: GET_ORGANIZATIONS_WITH_OWNER_BY_TYPE, fetchPolicy: 'network-only',
      variables() {
        return {orgType: [IDS.organizationTypes.Distributor]}
      },
      result({data, loading}) {
        if (!loading) {
          this.distributors = JSON.parse(JSON.stringify(data.Distributors))
        }
      },
    },
    Products: {
      query: GET_ALL_PRODUCTS_WITH_PRICES, fetchPolicy: 'network-only',
      variables() {
        return {priceListId: IDS.userPriceList.DistributorSurat, unitId: IDS.unitType.Box}
      },
      result({data, loading}) {
        if (!loading) {
          data.Products.forEach(p => { p['quantity'] = 0; p['priceObj'] = p.ProductPrices[0]||{} });       //Vue.set()
          this.products = JSON.parse(JSON.stringify(data.Products));
          this.$forceUpdate()
        }
      },
    },
  },
  methods: {
    prepareDistributorName(item){
      return item.name + ' - ' + item.orgCode;
    },
    onSelectDistributor(item){
      this.order.to = item?.id || null;
      this.order.toUserId = item?.Users[0]?.id || null;
    },
    async placeOrder () {
      this.loading = true;
      if (this.$refs.orderForm.validate()) {
        /* ------Check Empty OrderItems------- */
        let item = this.products.find(p => Number(p.quantity));
        if(!item) {
          this.$emit('showToast', { enable: true, color: 'red', message: 'Please enter quantity in at least one product' });
          return this.loading = false;
        }
        /* -----------x----------x------------ */
        let toOrgStock = [], updateToOrgStock = []
        let res = await this.$apollo.query({
          query: GET_ORGANIZATION_STOCK, fetchPolicy: 'network-only',
          variables: { toId: this.order.to, unitId: IDS.unitType.Box },
        })
        if (res.data) {
          toOrgStock = JSON.parse(JSON.stringify(res.data.ToOrganizationStock))
          let orderItems = []
          this.productsUpdatedData.filter(p => Number(p.quantity)).forEach(p => {
              orderItems.push({
                from: IDS.organizations.RAJ_WAFERS_MASTER, to: this.order.to,
                productId: p.id, unitId: IDS.unitType.Box,
                quantity: Number(p.quantity), perUnitPrice: parseFloat(p.priceObj.price),
                price: Number(p.quantity) * parseFloat(p.priceObj.price),
                weight: p.weight, noOfPieceInBox: p.noOfPieceInBox,
                createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
              })
          })
          let orderObj = {
            tallyInvoiceNum: this.order.tallyInvoiceNum, invoiceDate: this.order.invoiceDate, expectedDeliveryDate: this.order.expectedDeliveryDate,
            from: IDS.organizations.RAJ_WAFERS_MASTER, to: this.order.to, toUserId: this.order.toUserId,
            status: IDS.orderStatus.Dispatched, orderItemCount: this.calculateOrderItems(),
            gstObject: { gst: '12%' }, totalAmount: this.orderPricing.totalAmount,
              gstPrice: this.orderPricing.gst, finalAmount: Math.round(this.orderPricing.finalAmount),
            roundOfValue: this.orderPricing.finalAmount,
            createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
          }
          orderItems.forEach(orderItem => {
            let toObj = toOrgStock.find(toStock => toStock.productId === orderItem.productId)
            let updateToObj = {}
            if (toObj) {
              updateToObj['id'] = toObj.id
              updateToObj['organizationId'] = toObj.organizationId
              updateToObj['productId'] = toObj.productId
              updateToObj['quantity'] = parseInt(toObj.quantity) + Number(orderItem.quantity)
              updateToObj['unitId'] = toObj.unitId
            } else {
              updateToObj['organizationId'] = this.order.to
              updateToObj['productId'] = orderItem.productId
              updateToObj['quantity'] = Number(orderItem.quantity)
              updateToObj['unitId'] = IDS.unitType.Box
            }
            updateToOrgStock.push(updateToObj)
          })
          orderObj['OrderItems'] = { data: orderItems }
          let orders_on_conflict_rule = {
            constraint: 'PurchaseOrders_pkey',
            update_columns: [
              'tallyInvoiceNum', 'invoiceDate', 'expectedDeliveryDate', 'status', 'orderItemCount', 'gstObject', 'totalAmount', 'gstPrice',
              'gstObject', 'finalAmount', 'roundOfValue', 'updatedBy'],
          }
          let res1 = await this.$apollo.mutate({
            mutation: CREATE_UPDATE_PURCHASE_ORDER_ITEMS,
            variables: { orderObj: orderObj, orders_on_conflict_rule: orders_on_conflict_rule },
          })
          if (res1.data) {
            let organizationStock_on_conflict_rule = {
              constraint: 'OrganizationStock_pkey',
              update_columns: ['locationId', 'productId', 'unitId', 'quantity', 'totalQuantityInPackets'],
            }
            let res2 = await this.$apollo.mutate({
              mutation: CREATE_UPDATE_ORGANIZATION_STOCK,
              variables: {
                updateToOrgStock: updateToOrgStock,
                organizationStock_on_conflict_rule: organizationStock_on_conflict_rule,
              },
            })
            if (res2.data) {
              //Need to add Credit Entry????
              this.$emit('showToast', { enable: true, color: 'green', message: 'Order placed successfully' })
              this.$emit('saveOrder')
            }
          }
        }
      } else {
        this.loading = false
      }
    },
    increaseQuantity (index) {
      if (Number(this.products[index].quantity)) {
        const quantity = Number(this.products[index].quantity) + 1
        this.$set(this.products, index, { ...this.products[index], quantity });
      } else { 
        const quantity = 1 
        this.$set(this.products, index, { ...this.products[index], quantity });
      }
      this.productsUpdatedData = this.products
      this.$forceUpdate()
    },
    decreaseQuantity (index) {
      if (Number(this.products[index].quantity)) {
        const quantity = Number(this.products[index].quantity) - 1
        this.$set(this.products, index, { ...this.products[index], quantity });
        this.productsUpdatedData = this.products
        this.$forceUpdate()
      }
    },
    calculateOrderItems () {
      let total = 0
      this.productsUpdatedData.forEach(p => { 
        if (Number(p.quantity)) {
         const data = total += 1
         total = data
        } 
      })
      this.products = this.productsUpdatedData
      return total
    },
    calculateQuantity () {
      let total = 0
      this.productsUpdatedData.forEach(p => { total += Number(p.quantity) })
      this.calculateTotalAmount()
      return total
    },
    calculateTotalAmount () {
      let total = 0
      this.productsUpdatedData.forEach(p => { total += parseFloat(p.priceObj.price) * Number(p.quantity) })
      this.orderPricing.totalAmount = total
      this.orderPricing.cgst = total * 0.06
      this.orderPricing.sgst = total * 0.06
      this.orderPricing.gst = this.orderPricing.cgst + this.orderPricing.sgst
      this.orderPricing.finalAmount = this.orderPricing.totalAmount + this.orderPricing.gst
      return total
    },
    distributorFilter (item, queryText) {
      const name = item.name?.toLowerCase()
      const mobile = item.mobile?.toLowerCase()
      const orgCode = item.orgCode?.toLowerCase()
      const searchText = queryText.toLowerCase()
      return name && name.indexOf(searchText) > -1 || mobile && mobile.indexOf(searchText) > -1 || orgCode &&
          orgCode.indexOf(searchText) > -1
    },
    onSearch(val){
      val = val?.toLowerCase() || '';
      this.products.forEach(p => Vue.set(p, 'isHidden', p.displayName.toLowerCase().indexOf(val) === -1));
    },
    cancel () { this.$emit('cancel') },
  },
}
</script>

<style scoped>
/deep/ .stock-field .v-text-field__slot input {
  text-align: center !important;
}
.img-div{
  cursor: pointer;
}
</style>
<style>
.v-input__icon--append button {
  color: green !important;
  font-size: 16px !important;
}

.v-input__icon--prepend-inner button {
  font-size: 16px !important;
}
</style>
